.StyledSidebarHeader {
    height: 64px;
    margin-top: 10px;
    min-height: 64px;
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
}



.StyledLogo-dark {
    width: 35px;
    min-width: 35px;
    height: 35px;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: white;
    font-size: 24px;
    font-weight: 700;
    background-color: #725937;
    background: linear-gradient(45deg, #5b472b 0%, #a17f50 100%);

}

.StyledLogo-light {
    width: 35px;
    min-width: 35px;
    height: 35px;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: white;
    font-size: 24px;
    font-weight: 700;
    background-color: #725937;
    background: linear-gradient(45deg, #423a48 0%, #9e9d9f 100%);

}