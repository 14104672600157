.sidebar {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  display: block;
  padding-left: 2%;
  position: fixed;
  transition-behavior: normal;
  transition-delay: 0s;
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: ease;
  unicode-bidi: isolate;
  height: 100%;
  z-index: 1;
  /* Stay on top */
  top: 0;
  /* Stay at the top */
  left: 0;
  min-width: max-content;
  overflow-x: hidden  !important;
  overflow-y: hidden  !important;
  padding-bottom: 30px;
  background-color: #cccccc00 !important;
  border-color : #cccccc00 !important;
  /* Disable horizontal scroll */
}

.StyledSidebarDarkIcon {
  border-radius: 25%;
  color: #ffffff;
  background: linear-gradient(45deg, #5b472b 0%, #a17f50 100%);
}

.StyledSidebarLightIcon {
  border-radius: 25%;
  color: #dee0db;
  background: linear-gradient(45deg, #423a48 0%, #9e9d9f 100%);
}