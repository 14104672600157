.project-client {

    margin-inline-start: 5px;

}
.project-role {

    margin-inline-start: 20px;

}
.project-title {

    margin-inline-start: 30px;

}
.project-desc {
    margin-inline-start: 50px;
    margin-inline-end: 50px;
}

.projects{
    margin-top: 5px;
    margin-bottom: 100px;
    max-width: 1600px;
}

