* {
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.content {
  margin-top: 5%;
  margin-inline-start: 10%;
  margin-inline-end: 2%;
}

.App-link {
  color: #61dafb;
}

.main {
  width: calc(100% - 300px);
  float: right;
  padding-top: 5%;
  padding-right: 5%;
  padding-left: 2%;
  padding-bottom: 3%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.7);
  display: block;
  font-family: Quicksand, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  height: 100%;
  line-height: 27px;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  transition-behavior: normal;
  transition-delay: 0s;
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: ease;
  unicode-bidi: isolate;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-item-left {
  padding: 10px;
  flex: 15%;
  min-height: min-content;
}

.flex-item-right {
  padding: 10px;
  flex: 40%;
}

@media (max-width: 575px) {

  .flex-item-right,
  .flex-item-left {
    max-width: 100%;
    flex: 100%;
  }
}

.section-header {
  padding-left: 20px;
}

.subsection {
  padding-left: 10px;
}

.details {
  padding-left: 30px;
}

ul {
  list-style-type: circle;
  list-style-position: inside;
}

.dark {
  background-color: #2d2d2d;
  color: #f5f5f5;
  min-height: 100hv;
  height: 100%;
}

.light {
  background-color: #eee4c3;
  color: #000000;
  min-height: 100hv;
  height: 100vh;
}
.home {
  background-color: #c4b58cee;
  padding: 20px;
  max-width: 1200px;
}

.about {
  padding: 20px;
  max-width: 1600px;
}