.StyledSidebarFooter {
    width: 100%;
    display:flex-wrap;
    flex-direction:row;
    align-items:flex-start;
    align-self: flex-start;
    justify-content: center;
    padding: 20px;
    border-radius: 8px;
    margin: 10px;
    text-align: center;
}

.StyledCollapsedSidebarFooter {
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction:row;
    align-items:flex-end;
    align-self: flex-end;
    justify-content:center;
    cursor: pointer;
    border-radius: 50%;
}

.StyledSidebarDarkFooter {
    color: #000000;
    background: linear-gradient(45deg, #5b472b 0%, #a17f50 100%);
}

.StyledSidebarLightFooter {
    color: #dee0db;
    background: linear-gradient(45deg, #423a48 0%, #9e9d9f 100%);
}